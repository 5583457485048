<template>
    <HeaderLogo />
    <div class="container">
        <h1>Privacy Policy</h1>
        <p><strong>Effective Date:</strong> Januray 6th, 2025</p>

        <h2>Introduction</h2>
        <p>Vaga is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard
            your information when you use our app.</p>

        <h2>Information We Collect</h2>
        <ul>
            <li><strong>Personal Information:</strong> When you sign up or use the app, we may collect your email
                address and other basic account details.</li>
            <li><strong>Trip Data:</strong> We collect data related to the trips you create, including destinations,
                POIs (Points of Interest), and collaborators added to trips.</li>
            <li><strong>Usage Data:</strong> We may collect information on how you use the app to improve functionality
                and user experience.</li>
        </ul>

        <h2>How We Use Your Information</h2>
        <ul>
            <li>To provide and improve the app's features and services.</li>
            <li>To allow you to create, manage, and share trips with others.</li>
            <li>To process data for personalized suggestions and AI-assisted POI recommendations.</li>
        </ul>

        <h2>Sharing Your Information</h2>
        <p>We do not sell your personal information. Data may be shared with trusted third-party service providers
            strictly for operational purposes (e.g., data hosting).</p>

        <h2>Account Deletion</h2>
        <p>You can request to delete your account and all associated data by contacting us via <a
                href="mailto:sylvain.huprelle@gmail.com">email</a>.</p>

        <h2>Data Security</h2>
        <p>We implement reasonable security measures to protect your data. However, no system is completely secure, and
            we cannot guarantee absolute security.</p>

        <h2>Changes to this Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time.</p>

        <h2>Contact Us</h2>
        <p>If you have any questions about this Privacy Policy or our practices, please <a
                href="mailto:sylvain.huprelle@gmail.com">contact us</a></p>

        <div class="footer">
            <p>© 2025 SH. All rights reserved.</p>
        </div>
    </div>
</template>

<script setup>
</script>

<style scoped>
h1,
h2 {
    color: #0FBA81;
}

a {
    color: #0FBA81;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.container {
    max-width: 800px;
    margin: auto;
}

.footer {
    margin-top: 20px;
    font-size: 0.9em;
    color: #666;
}
</style>
