<template>
    <HeaderLogo />
    <div style="margin: 1em; text-align: left;">
        <div style="margin-bottom: 2em; text-align: left;">
            <p>Discover. Plan. Explore. Your Travel Companion Awaits!</p>

            <p>🌍 Create Unforgettable Adventures</p>
            <p>Plan trips like never before! Whether it's a single destination or a multi-stop journey, our app helps
                you
                craft
                the perfect itinerary effortlessly.</p>

            <p>📍 Add Points of Interest with Ease</p>
            <p>Manually add your favorite spots or let our AI suggest must-visit locations tailored to your preferences.
                From
                hidden gems to iconic landmarks, we've got you covered.</p>

            <p>👥 Travel Together</p>
            <p>Collaborate with friends and family by adding them to your trips. Share ideas, explore together, and make
                every
                journey a shared experience.</p>

            <p>✅ Mark Your Adventures</p>
            <p>Keep track of your journey by marking POIs as visited. Celebrate each milestone as you explore the world!
            </p>

            <p>🚀 Exciting Features Coming Soon</p>
            <p>Get a sneak peek of basic itinerary planning today—future updates will bring smarter, more detailed
                itineraries
                to elevate your travel game!</p>

            <p>Your next adventure starts here. Download now and start exploring!</p>
        </div>
        <h2>Need Help?</h2>
        <p>Please <a href="mailto:sylvain.huprelle@gmail.com">contact us</a></p>
    </div>
    <!-- <div class="trips" v-if="tripStore.trips">
        <div class="trip-add">
            <Button @click="openModal()" rounded><i class="fa fa-plus fa-lg"></i> Add Trip</Button>
        </div>

        <div class="card flex justify-center">
            <Drawer v-model:visible="isModalOpened" header="New Trip" position="full">
                <div>
                    <div class="new-trip-form-item">
                        <InputText id="newTripName" v-model="name" placeholder="Name" />
                    </div>
                    <div class="new-trip-form-item">
                        <div>
                            Reason
                        </div>
                        <div>
                            <SelectButton v-model="selectedReason" :options="tripReasons" optionLabel="name" multiple
                                aria-labelledby="multiple" />
                        </div>
                    </div>
                </div>
                <div class="margin-vertical" v-if="!tripStore.foundLocation">
                    <div class="new-trip-form-item">
                        <InputText v-model="destination" placeholder="First destination" />
                    </div>
                    <div class="new-trip-form-item">
                        <div>
                            Dates
                        </div>
                        <div class="new-trip-dates">
                            <DatePicker v-model="dates" selectionMode="range" :manualInput="false" />
                        </div>
                    </div>
                    <Button @click="searchLocation()" :disabled="destination === ''" rounded>Start Your Trip!</Button>
                </div>
                <div v-if="tripStore.foundLocation">
                    <Timeline :value="tripStore.foundLocation.destinations">
                        <template #content="slotProps">
                            <div v-if="slotProps.item.place_id" class="timeline-destination-infos">
                                <div class="timeline-destination-name">{{
        slotProps.item.name }}
                                </div>
                                <div class="timeline-destination-country">{{
        slotProps.item.country }}
                                </div>
                                <div class="timeline-destination-dates">
                                    {{ new Date(slotProps.item.dates[0]).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric'
    }) }} - {{ new Date(slotProps.item.dates[1]).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    }) }}
                                </div>
                            </div>
                            <div v-if="!slotProps.item.place_id" class="timeline-destination-infos timeline-search">
                                <div class="timeline-search-input">
                                    <InputText type="text" v-model="destinationSearchText" />
                                    <Button @click="searchDestination()" rounded><i class="fas fa-search"></i></Button>
                                </div>
                                <div v-if="tripStore.nextDestination" class="timeline-destination-infos">
                                    <div class="timeline-destination-name">{{
        tripStore.nextDestination.name }}
                                    </div>
                                    <div class="timeline-destination-country">{{
        tripStore.nextDestination.country }}
                                    </div>
                                </div>
                                <div class="flex justify-center margin-vertical" v-if="tripStore.nextDestination">
                                    <DatePicker v-model="dates" selectionMode="range" :manualInput="false" />
                                </div>
                                <div class="flex justify-center margin-vertical">
                                    <Button @click="validateDestination()" :disabled="!tripStore.nextDestination"
                                        rounded>Validate</Button>
                                </div>
                            </div>
                        </template>
<template #opposite="slotProps">
                            <div v-if="slotProps.item.place_id" class="timeline-destination"
                                :key="slotProps.item.place_id">
                                <div class="timeline-destination-image" :style="slotProps.item.image ?
        'background-image: url(https://pub-b93990d14f63428d91fefbf1f1143791.r2.dev/' + slotProps.item.image
        : 'background-color: #666'">
                                    <div class="timeline-destination-photo-refresh"
                                        @click="updatePhoto(slotProps.item._id)"><i
                                            class="fa-solid fa-arrows-rotate"></i>
                                    </div>
                                </div>
                            </div>
                            <div v-if="!slotProps.item.place_id && tripStore.nextDestination"
                                class="timeline-destination">
                                <div class="timeline-destination-image" :style="tripStore.nextDestination.image ?
        'background-image: url(https://pub-b93990d14f63428d91fefbf1f1143791.r2.dev/' + tripStore.nextDestination.image
        : 'background-color: #666'">
                                </div>
                            </div>
                            <div v-if="!slotProps.item.place_id && !tripStore.nextDestination"
                                class="timeline-destination">
                                <Skeleton height="4rem" width="100%" class="mb-2"></Skeleton>
                            </div>
                        </template>
</Timeline>
</div>
<div class="flex justify-center margin-vertical">
    <Button @click="addTrip()" :disabled="!tripStore.foundLocation" rounded>Save Trip</Button>
</div>
</Drawer>
</div>

<div>
    <div class="trip-list-header" v-if="tripStore.currentTrip._id">
        Current Trip
    </div>
    <div class="current-trip" v-if="tripStore.currentTrip._id">
        <Card style="width: 100%; overflow: hidden">
            <template #header>
                        <div class="destination-image" :style="tripStore.currentTrip.destinations[0].image ?
        'background-image: url(https://pub-b93990d14f63428d91fefbf1f1143791.r2.dev/' + tripStore.currentTrip.destinations[0].image
        : 'background-color: #666'" @click="goToTripView($event, tripStore.currentTrip)">
                        </div>
                    </template>
            <template #title>
                        <div class="destination-name" @click="goToTripView($event, tripStore.currentTrip)">{{
        tripStore.currentTrip.name }}
                        </div>
                    </template>
            <template #subtitle>
                        <div class="trip-date" @click="goToTripView($event, tripStore.currentTrip)">
                            {{ new Date(tripStore.currentTrip.dates[0]).toLocaleDateString('fr-FR', {
        month: 'short',
        day: 'numeric'
    }) }}
                            <br />
                            {{ new Date(tripStore.currentTrip.dates[1]).toLocaleDateString('fr-FR', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    }) }}
                        </div>
                    </template>
            <template #content>
                        <Timeline :value="tripStore.currentTrip.destinations">
                            <template #opposite="slotProps">
                                <div class="timeline-destination-infos"
                                    @click="goToTripMap($event, tripStore.currentTrip, slotProps.item.location.coordinates)">
                                    <div class="timeline-destination-name">
                                        {{
        slotProps.item.name }}
                                    </div>
                                    <div class="timeline-destination-country">{{
        slotProps.item.country }}
                                    </div>

                                    <div class="timeline-destination-length">{{ slotProps.item.nbDays }} {{
        slotProps.item.nbDays > 1 ? 'days' : 'day' }}</div>
                                </div>
                            </template>
            <template #content="slotProps">
                                <div class="timeline-destination" :key="slotProps.item.place_id">
                                    <div class="timeline-destination-image" :style="slotProps.item.image ?
        'background-image: url(https://pub-b93990d14f63428d91fefbf1f1143791.r2.dev/' + slotProps.item.image
        : 'background-color: #666'">
                                        <div class="timeline-destination-photo-refresh"
                                            @click="updatePhoto(slotProps.item._id)"><i
                                                class="fa-solid fa-arrows-rotate"></i>
                                        </div>
                                    </div>
                                </div>
                            </template>
            </Timeline>
            </template>
            <template #footer>
                        <div class="flex">
                            <div class="trip-poi-count" @click="goToTripMap($event, tripStore.currentTrip)">
                                <i class="fa-solid fa-map-marker"></i> {{ tripStore.currentTrip.poiCount }} POIs
                            </div>
                            <div class="trip-note-count" @click="goToNotes($event, tripStore.currentTrip)">
                                <i class="fa-solid fa-pen-nib"></i> {{ tripStore.currentTrip.noteCount }} notes
                            </div>
                        </div>
                    </template>
        </Card>
    </div>
    <div class="trip-list-header">
        Next Trips
    </div>
    <div class="trip-list">
        <div class="skeletons" v-if="isLoadingTrips">
            <Skeleton height="8rem" class="mb-2"></Skeleton>
        </div>
        <div class="no-trip" v-if="isLoadingTrips === false && tripStore.nextTrips.length === 0">
            <Message severity="secondary">Nothing here... Plan your next trip!</Message>
        </div>
        <Card style="width: 100%; overflow: hidden" v-for="trip in tripStore.nextTrips" :key="trip._id">
            <template #header>
                        <div class="destination-image" :style="trip.destinations[0].image ?
        'background-image: url(https://pub-b93990d14f63428d91fefbf1f1143791.r2.dev/' + trip.destinations[0].image
        : 'background-color: #666'" @click="goToTripView($event, trip)">
                        </div>
                    </template>
            <template #title>
                        <div class="destination-name" @click="goToTripView($event, trip)">{{
        trip.name }}
                        </div>
                    </template>
            <template #subtitle>
                        <div class="trip-date" @click="goToTripView($event, trip)">
                            {{ new Date(trip.dates[0]).toLocaleDateString('fr-FR', {
        month: 'short',
        day: 'numeric'
    }) }}
                            <br />
                            {{ new Date(trip.dates[1]).toLocaleDateString('fr-FR', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    }) }}
                        </div>
                    </template>
            <template #content>
                        <Timeline :value="trip.destinations">
                            <template #opposite="slotProps">
                                <div class="timeline-destination-infos"
                                    @click="goToTripMap($event, trip, slotProps.item.location.coordinates)">
                                    <div class="timeline-destination-name">{{
        slotProps.item.name }}
                                    </div>
                                    <div class="timeline-destination-country">{{
        slotProps.item.country }}
                                    </div>
                                    <div class="timeline-destination-length">{{ slotProps.item.nbDays }} {{
        slotProps.item.nbDays > 1 ? 'days' : 'day' }}</div>
                                </div>
                            </template>
            <template #content="slotProps">
                                <div class="timeline-destination" :key="slotProps.item.place_id">
                                    <div class="timeline-destination-image" :style="slotProps.item.image ?
        'background-image: url(https://pub-b93990d14f63428d91fefbf1f1143791.r2.dev/' + slotProps.item.image
        : 'background-color: #666'">
                                        <div class="timeline-destination-photo-refresh"
                                            @click="updatePhoto(slotProps.item._id)"><i
                                                class="fa-solid fa-arrows-rotate"></i>
                                        </div>
                                    </div>
                                </div>
                            </template>
            </Timeline>
            </template>
            <template #footer>
                        <div class="flex">
                            <div class="trip-poi-count" @click="goToTripMap($event, trip)">
                                <i class="fa-solid fa-map-marker"></i> {{ trip.poiCount }} POIs
                            </div>
                            <div class="trip-note-count" @click="goToNotes($event, trip)">
                                <i class="fa-solid fa-pen-nib"></i> {{ trip.noteCount }} notes
                            </div>
                        </div>
                    </template>
        </Card>
    </div>
    <div class="trip-list-header">
        Past Trips
    </div>
    <div class="trip-list">
        <div class="skeletons" v-if="isLoadingTrips">
            <Skeleton height="8rem" class="mb-2"></Skeleton>
            <Skeleton height="8rem" class="mb-2"></Skeleton>
            <Skeleton height="8rem" class="mb-2"></Skeleton>
        </div>
        <div class="no-trip" v-if="isLoadingTrips === false && tripStore.pastTrips.length === 0">
            No trip
        </div>
        <Card style="width: 100%; overflow: hidden" v-for="trip in tripStore.pastTrips" :key="trip._id">
            <template #header>
                        <div class="destination-image" :style="trip.destinations[0].image ?
        'background-image: url(https://pub-b93990d14f63428d91fefbf1f1143791.r2.dev/' + trip.destinations[0].image
        : 'background-color: #666'" @click="goToTripView($event, trip)">
                        </div>
                    </template>
            <template #title>
                        <div class="destination-name" @click="goToTripView($event, trip)">{{
        trip.name }}
                        </div>
                    </template>
            <template #subtitle>
                        <div class="trip-date" @click="goToTripView($event, trip)">
                            {{ new Date(trip.dates[0]).toLocaleDateString('fr-FR', {
        month: 'short',
        day: 'numeric'
    }) }}
                            <br />
                            {{ new Date(trip.dates[1]).toLocaleDateString('fr-FR', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric'
                            }) }}
                        </div>
                    </template>
            <template #content>
                        <Timeline :value="trip.destinations">
                            <template #opposite="slotProps">
                                <div class="timeline-destination-infos"
                                    @click="goToTripMap($event, trip, slotProps.item.location.coordinates)">
                                    <div class="timeline-destination-name">{{
                                        slotProps.item.name }}
                                    </div>
                                    <div class="timeline-destination-country">{{
                                        slotProps.item.country }}
                                    </div>
                                    <div class="timeline-destination-length">{{ slotProps.item.nbDays }} {{
                                        slotProps.item.nbDays > 1 ? 'days' : 'day' }}</div>
                                </div>
                            </template>
            <template #content="slotProps">
                                <div class="timeline-destination" :key="slotProps.item.place_id">
                                    <div class="timeline-destination-image" :style="slotProps.item.image ?
        'background-image: url(https://pub-b93990d14f63428d91fefbf1f1143791.r2.dev/' + slotProps.item.image
        : 'background-color: #666'">
                                        <div class="timeline-destination-photo-refresh"
                                            @click="updatePhoto(slotProps.item._id)"><i
                                                class="fa-solid fa-arrows-rotate"></i>
                                        </div>
                                    </div>
                                </div>
                            </template>
            </Timeline>
            </template>
            <template #footer>
                        <div class="flex">
                            <div class="trip-poi-count" @click="goToTripMap($event, trip)">
                                <i class="fa-solid fa-map-marker"></i> {{ trip.poiCount }} POIs
                            </div>
                            <div class="trip-note-count" @click="goToNotes($event, trip)">
                                <i class="fa-solid fa-pen-nib"></i> {{ trip.noteCount }} notes
                            </div>
                        </div>
                    </template>
        </Card>
    </div>
</div>
</div> -->
</template>

<script setup>
/*eslint-disable no-unused-vars*/
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import HeaderLogo from "../components/HeaderLogo.vue";
import { useTripStore } from '@/stores/trip';
import { usePOIStore } from '@/stores/pois';
import Button from 'primevue/button';
import Drawer from 'primevue/drawer';
import Card from 'primevue/card';
import Timeline from 'primevue/timeline';
import Message from 'primevue/message';
import Skeleton from 'primevue/skeleton';
import InputText from 'primevue/inputtext';
import SelectButton from 'primevue/selectbutton';
import DatePicker from 'primevue/datepicker';

const router = useRouter();

const tripStore = useTripStore();
const isLoadingTrips = ref(false);

onMounted(() => {
    /*isLoadingTrips.value = true
    tripStore.fetchTrips().then(() => {
        isLoadingTrips.value = false;
    });*/
})

const goToTripMap = (e, trip, location) => {
    tripStore.setSelected(tripStore.trips.find(option => trip._id == option._id));
    if (location) {
        router.push({ path: '/map', query: { tripId: trip._id, location: [location[0], location[1]].join(",") } });
    } else {
        router.push({ path: '/map', query: { tripId: trip._id } });
    }
}

const goToTripView = (e, trip) => {
    tripStore.setSelected(tripStore.trips.find(option => trip._id == option._id));
    router.push({ path: '/trips/' + trip._id });
}

const goToNotes = (e, trip) => {
    tripStore.setSelected(tripStore.trips.find(option => trip._id == option._id));
    router.push({ path: '/notes', query: { tripId: trip._id } });
}

const updatePhoto = (tripId) => {
    tripStore.updateTripPhoto(tripId);
}

const isModalOpened = ref(false);
const openModal = (tripId) => {
    isModalOpened.value = true;
};
const closeModal = () => {
    isModalOpened.value = false;
};

const submitHandler = () => {
    //here you do whatever
}

const name = ref();
const destination = ref();
const country = ref();
const dates = ref();
const startDate = new Date();
const endDate = new Date(new Date().setDate(startDate.getDate() + 7));
dates.value = [startDate, endDate];
const selectedReason = ref();
const tripReasons = ref([
    { name: 'Holidays', value: 'holidays' },
    { name: 'Work', value: 'work' },
    { name: 'Remote', value: 'remote' }
]);

const addDays = (date, days) => {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + days);
    return newDate;
}

const offsetDates = () => {
    const defaultTo = addDays(dates.value[1], 7);
    dates.value = [dates.value[1], defaultTo];
}

const searchLocation = () => {
    tripStore.findLocation(destination.value, country.value, dates.value);
    offsetDates();
}

const destinationSearchText = ref("");
const searchDestination = () => {
    tripStore.searchDestination(destinationSearchText.value);
}
const validateDestination = () => {
    tripStore.addNextDestination(dates.value);
    offsetDates();
    destinationSearchText.value = "";
}

const addTrip = () => {
    tripStore.addTrip(name.value,
        dates.value[0],
        dates.value[1],
        selectedReason.value);
    tripStore.foundLocation.value = null;
    closeModal();
}
</script>

<style scoped>
.p-drawer-content {
    padding: 1.25rem;
}

.p-card {
    display: grid;
    grid-template-columns: 2fr 3fr;
    background-color: var(--p-primary-100) !important;
    color: var(--p-primary-950) !important;
    margin-bottom: 1em;
}

.p-card-header {
    width: 100%;
    padding: 0;
    position: relative;
    overflow: hidden;
}

.p-card-header>img {
    flex-shrink: 0;
    width: auto;
    height: 100%;
}

.trip-add {
    position: fixed;
    bottom: 60px;
    padding: 1em 0;
    z-index: 10;
    width: 100%;
}

.destination-image,
.timeline-destination-image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: auto;
    height: 100%;
    overflow: hidden;
    padding: 0;
    position: relative;
}

.timeline-destination-image {
    border-radius: 8px;
}

.destination-image img,
.timeline-destination-image img {
    position: absolute;
    left: 0;
    top: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
}

.timeline-destination-infos {
    font-size: 14px;
}

.timeline-search .p-inputtext {
    width: 100%;
}

.timeline-search-input {
    display: flex;
    gap: 0.2rem;
    margin-bottom: 0.5rem;
}

.timeline-destination-name,
.timeline-destination-country,
.timeline-destination-dates,
.timeline-destination-length {
    width: 100%;
    overflow: hidden;
}

.timeline-destination-country {
    font-size: 12px;
}

.timeline-destination-length {
    font-size: 12px;
    margin-top: 0.2rem;
    color: var(--p-primary-700);
}

.timeline-destination-dates {
    color: #666;
}

.trips {
    box-sizing: border-box;
    overflow-y: scroll;
    margin-bottom: 60px;
}

.trips::-webkit-scrollbar {
    display: none;
}

.trip-list-header {
    margin: 1rem;
    text-align: left;
    font-size: 20px;
}

.current-trip {
    margin: 1em;
}

.no-trip {
    text-align: left;
}

.trip-list {
    margin: 1em 1em 2em 1em;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
}

.trip-left {
    padding: 1em;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    font-weight: 600;
}

.trip-middle {
    box-sizing: border-box;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    padding: 1em;
    text-align: right;
    font-size: 11px;
    color: #fff;
    text-shadow: -1px 0 #666, 0 1px #666, 1px 0 #666, 0 -1px #666;
}

.trip-map {
    position: absolute;
    top: 0.4em;
    right: 0.4em;
}

.trip-destination {
    font-size: 20px;
}

.trip-date {
    font-size: 14px;
    color: var(--p-primary-700);
}

.trip-poi-count {
    width: 100%;
    font-size: 14px;
    font-weight: 900;
    color: var(--p-primary-700);
}

.trip-note-count {
    width: 100%;
    font-size: 14px;
    font-weight: 900;
    color: var(--p-primary-700);
}

.trip-photo-refresh {
    position: absolute;
    bottom: 0.4em;
    right: 0.4em;
}

.trip-pois {
    box-sizing: border-box;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.3em;
    margin-bottom: 0.6em;
}

.trip-pois-extra {
    box-sizing: border-box;
    width: 100%;
}

.trip-poi {
    flex: 1;
    display: grid;
}

.trip-poi-name {
    margin: 0.2em 0;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.trip-poi-image {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 3px;
    height: 140px;
    width: 100%;
    margin: auto;
}

.trip-poi-mini {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 0.3em;
    margin-bottom: 0.6em;
}

.trip-poi-mini-image {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 3px;
    max-height: 40px;
    width: 100%;
    margin: auto;
}

.trip-poi-image-img {
    flex-shrink: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.trip-poi-mini-image-img {
    flex-shrink: 0;
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
}

.trip-poi-count {
    box-sizing: border-box;
    width: 100%;
    gap: 0.2em;
}

.trip-poi-name-all {
    margin: auto 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.poi-accomodation {
    background-color: #FF385C;
}

.trip-poi-search {
    margin-bottom: 0.6em;
    display: flex;
    gap: 0.4em;
}

.trip-poi-input {
    flex: 4;
    box-sizing: border-box;
    width: 100%;
    padding: 0.4em;
    font-size: 16px;
}

.trip-poi-search-enter,
.trip-poi-validate {
    flex: 1;
    max-width: fit-content;
    font-weight: 600;
    color: #fff;
    background-color: var(--p-primary-color);
    border-radius: 20px;
    padding: 0.6em;
}

.modal-container {
    width: 350px;
    max-width: 80%;
}

.new-trip-form-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
}

.new-trip-form-item>div:first-child {
    margin: auto 0;
}

.new-trip-form-item input {
    margin: auto 0;
    width: 100%;
}

.new-trip-dates {
    display: flex;
}

.new-trip-submit {
    font-weight: 600;
    color: #fff;
    background-color: var(--p-primary-color);
    border-radius: 20px;
    margin: 0.6em auto;
    padding: 0.6em;
}

.new-trip-destinations {
    max-height: 30vh;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.new-trip-destination {
    display: grid;
    grid-template-columns: 50% auto;
    margin-bottom: 0.6em;
    width: 100%;
}

.new-trip-destination img {
    width: 100%;
}

.destinations {
    margin: 1em 1em 1em 1em;
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.4em;
}

.destination {
    border-radius: 16px;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    text-shadow: -1px 0 #666, 0 1px #666, 1px 0 #666, 0 -1px #666;
}

.timeline-destination {
    width: 100%;
    height: 90%;
}

.timeline-destination-image img {
    width: 100%;
    border-radius: 8px;
}

.timeline-destination-photo-refresh {
    position: absolute;
    right: 0;
    margin: 0.2em;
}
</style>